AFRAME.registerComponent("glowing", {
  schema: {
    maxAlpha: { type: "number", default: 0.5 },
  },

  init: function () {
    const el = this.el;
    const maxAlpha = this.data.maxAlpha;

    const material = new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 1.0 },
        maxAlpha: { value: maxAlpha },
      },
      vertexShader: `
          void main() {
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `,
      fragmentShader: `
          uniform float time;
          uniform float maxAlpha;

          void main() {
            float pulse = sin(time) * 0.5 + 0.5;
            
            // Create a yellow color with varying alpha for pulsation
            vec3 glowColor = vec3(1.0, 0.8, 0.0); // Yellow color (red:1, green:0.8, blue:0)
            
            // Pulsate alpha between 0 and maxAlpha
            float alpha = pulse * maxAlpha; // Alpha pulsates between 0 and maxAlpha
            
            // Adjust glow intensity based on pulse
            float glowIntensity = 0.8 + pulse * 0.2; // Adjust intensity range as needed
            
            gl_FragColor = vec4(glowColor * glowIntensity, alpha);
          }
        `,
      transparent: true, // Enable transparency
    });

    el.getObject3D("mesh").material = material;

    this.tick = function (time, deltaTime) {
      material.uniforms.time.value += deltaTime / 1000;
    };
  },
});
